window.contactForm = function () {
  return {
    submit(form) {
      let success = false;
      let errors = false;
      let formData = this.serialize(new FormData(form));
      let additionalParameters = {};

      if (form.getAttribute('data-location')) {
        additionalParameters.form_location = form.getAttribute('data-location');
      }

      if (form.getAttribute('data-resource-id')) {
        additionalParameters.resource_id = form.getAttribute(
          'data-resource-id'
        );
      }

      if (form.getAttribute('data-resource-type')) {
        additionalParameters.resource_type = form.getAttribute(
          'data-resource-type'
        );
      }

      // Set the form 'submitting' state
      let submitBtn = form.querySelector('[role="form-submit-btn"]');
      let submitBtnText = submitBtn.innerHTML;
      submitBtn.innerHTML = 'Submitting...';
      submitBtn.disabled = true;

      let successBanner = form.querySelector('[role="form-success-message"]');
      let errorBanner = form.querySelector('[role="form-error-message"]');
      errorBanner.style.display = 'none';
      successBanner.style.display = 'none';

      fetch(form.getAttribute('data-target'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          form_id: form.getAttribute('data-id'),
          ...additionalParameters,
          data: {
            form_name: form.getAttribute('data-name'),
            ...formData
          }
        })
      })
        .then((response) => {
          // Set success / error states for later
          if (!response.ok) {
            errors = true;
            success = false;
          } else {
            errors = false;
            success = true;

            this.reset(form);
          }
          return response.json();
        })
        .then((data) => {
          // if there is an error...
          if (!success && errors) {
            if (
              data.error != undefined &&
              data.error.display_message != undefined
            ) {
              throw new Error(data.error.display_message);
            }
            throw new Error('Something went wrong');
          }

          // else...
          errorBanner.style.display = 'none';
          successBanner.style.display = 'flex';

          if (data.actions.data != undefined) {
            for (let i = 0; i < data.actions.data.length; i++) {
              if (data.actions.data[i].type.id == 'redirect') {
                window.location.href = data.actions.data[i].redirect_url;
                break;
              }
              if (data.actions.data[i].type.id == 'success') {
                successBanner.innerHTML = data.actions.data[i].success_message;
                break;
              }
            }
          }
        })
        .catch((err) => {
          // Display a custom error message
          errorBanner.querySelector(
            '[role="form-error-message-text"]'
          ).innerHTML = err.message;
          errorBanner.style.display = 'flex';
          successBanner.style.display = 'none';
          errors = true;
          success = false;
        })
        .finally(() => {
          // Hide the form 'submitting' state
          submitBtn.innerHTML = submitBtnText;
          submitBtn.disabled = false;
        });
    },

    reset(form) {
      form.reset();

      if (form.getAttribute('data-recaptcha')) {
        grecaptcha.reset();
      }
    },

    serialize(data) {
      let obj = {};
      for (let [key, value] of data) {
        if (obj[key] !== undefined) {
          if (!Array.isArray(obj[key])) {
            obj[key] = [obj[key]];
          }
          obj[key].push(value);
        } else {
          obj[key] = value;
        }
      }
      return obj;
    }
  };
};
